.gray-button {
  background: #EBEBEB 0% 0% no-repeat padding-box;
}

.purple-button {
  background: #808FFF 0% 0% no-repeat padding-box;
}


.content {
  background-color: #FFFFFF;
  padding         : 0px 80px 0px 80px;

}



.container {
  height: calc(100vh - 150px)
}


.center-image-area {
  height: 32vh;
}

.center-image-container-end {
  display        : inline-flex;
  justify-content: center;
  align-items    : center;
  width          : 100%;
  margin-top     : 13vh;

}


.center-image-container {
  display        : inline-flex;
  justify-content: center;
  align-items    : center;
  width          : 100%;
  margin-top     : 20vh;

}

.center-image-container-static {
  display        : inline-flex;
  justify-content: center;
  align-items    : center;
  width          : 100%;
  margin-top     : 3vh;
    .animation-svg {
      width: 250px;
    }
}

.center-ai-image-container {
  display        : inline-flex;
  justify-content: center;
  align-items    : center;
  width          : 100%;
  margin-top     : 3vh;
  animation      : rotation 5s infinite linear;
}

.zoomin-zoomout {
  animation: zoom-in-zoom-out 1s ease infinite !important;
}

.blink {
  animation: blink 1s linear infinite !important;

}

@keyframes blink {
  25% {
    opacity: .5;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: .5;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.no-image-area{
  height: 140px;
}

.link-cursor {
  cursor: pointer;
}

.icon {
  margin-bottom: 11px;
}

.icon-inner-text {
  margin-left: 8px;
}

.interview-buttons {
  vertical-align: middle;
  text-align    : center;


  border-radius: 33px;
  border       : none;

  font-weight: 500;
  font-size  : 26px;

  padding-top: 8px;

  width : 160px;
  height: 56px;
}

.interview-buttons-long {
  vertical-align: middle;
  text-align    : center;
  

  border-radius: 33px;
  border       : none;

  font-weight: 500;
  font-size  : 26px;

  padding-top  : 10px;

  width        : 375px;
  height       : 56px;
}

.interview-buttons-long:hover {
  background: #000 0% 0% no-repeat padding-box;
  color     : white;
}

.interview-buttons:hover {
  background: #000 0% 0% no-repeat padding-box;
  color     : white;
}

.interview-border {
  border       : 1px solid #000000;
  border-radius: 52px;
  opacity      : 1;
  background: #F7F7F7 0% 0% no-repeat padding-box;
}

.interview-center-line-left {
  border-right       : 1px solid #000000;
  opacity      : 1;
  border-radius: 51px 0 0 51px;
 
}

.interview-center-line-right {
  border-left       : 1px solid #000000;
  opacity      : 1;
  border-radius: 0 51px 51px 0;
  margin-left: -1px;
}

.step-container {
  background   : #F7F8FF 0% 0% no-repeat padding-box;
  border       : 1px solid #F7F8FF;
  border-radius: 78px;
  min-height   : 260px;
  padding      : 50px 20px 20px 20px;
}

.user-text {
  text-align: center;

  letter-spacing: -0.6px;
  color         : #000000;
  opacity       : 1;
  vertical-align: middle;
  font-size     : 38px;
  margin-top    : 2vh;
  text-align    : center;
}

.interview-body {
  text-align: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  /* min-height: 73vh; */
}
.interview-text {
  text-align: center;
  letter-spacing: -0.6px;
  color         : #000000;
  opacity       : 1;
  vertical-align: middle;
  margin        : 4vh 10vh 0vh 10vh;
  text-align    : center;
  min-height: 5vh;
  font-size: 22.5px;
  line-height: 1.25;

  .topicImage{
    min-height: 20vh;
    max-height: 37vh;
    padding-bottom: 3vh;
  }

  .topicVideo{
    min-height: 20vh;
    max-height: 37vh;
    padding-bottom: 3vh;
    width: 100% !important;
  }
}


.step-text {
  text-align    : left;
  font-size     : 26px;
  letter-spacing: -0.52px;
  color         : #6B7CFF;

}

.steps-row {
  height       : 30vh;
  margin-bottom: 30vh;
}

@media (max-width: 1500px) {

  .interview-text {
    letter-spacing: -0.44px;
    text-align: center;
    letter-spacing: -0.6px;
    color         : #000000;
    opacity       : 1;
    vertical-align: middle;
    margin        : 4vh 10vh 0vh 10vh;
    text-align    : center;
    font-size: 22.5px;
    line-height: 1.25;
  }

  .user-text {
    text-align: center;
    letter-spacing: -0.6px;
    color         : #000000;
    opacity       : 1;
    vertical-align: middle;
    font-size     : 32px;
    margin-top    : 2vh;
    text-align    : center;
  }

  .step-text {
    text-align    : left;
    font-size     : 20px;
    letter-spacing: -0.52px;
    color         : #6B7CFF;

  }
}

@media (max-width: 1000px) {
  .steps-row {
    height       : 50vh;
    margin-bottom: 30vh;
  }

}

@media (max-width: 1200px) {

  .interview-text {
    text-align: center;
    letter-spacing: -0.6px;
    color         : #000000;
    opacity       : 1;
    vertical-align: middle;
    margin        : 4vh 10vh 0vh 10vh;
    text-align    : center;
    font-size: 22.5px;
    line-height: 1.25;
  }

  .user-text {
    text-align: center;

    letter-spacing: -0.6px;
    color         : #000000;
    opacity       : 1;
    vertical-align: middle;
    font-size     : 28px;
    margin-top    : 2vh;
    text-align    : center;
  }

  .step-text {
    text-align    : left;
    font-size     : 16px;
    letter-spacing: -0.52px;
    color         : #6B7CFF;

  }
}

.flex-center {
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.blue-header {
  font          : normal normal medium 50px/55px Haffer SQ TRIAL;
  letter-spacing: 0px;
  color         : #808FFF;
  font-size     : 50px;

}
.timer-area-container{
  text-align: center;
}

.pause-timer-area {
  border-radius: 33px;
  border       : none;
  width        : 375px;
  height       : 56px;
  text-align   : center;
  letter-spacing: -0.52px;
  font-size     : 26px;
  margin: 0 auto 10px auto;
 padding-top: 13px;
  position: relative;
}

.record-audio-area{
  border-radius: 33px;
  border       : none;
  width        : 375px;
  height       : 56px;
  text-align   : center;
  letter-spacing: -0.52px;
  font-size     : 26px;
  margin: 0 auto 10px auto;

  position: relative;
}

.pause-button-area {
  width     : 210px;
  height    : 56px;
  text-align: center;
  letter-spacing: -0.52px;
  font-size     : 26px;
  padding-top: 8px;
  margin: 0 auto 28px;
}
.timer-area-main {
  position: relative;
  padding-top: 18px;
  text-align: center;
}

.timer-area {
  border-radius: 33px;
  border       : none;
  width        : 210px;
  height       : 56px;
  text-align   : center;
  letter-spacing: -0.52px;
  font-size     : 26px;
  padding-top: 8px;
  margin: 0 auto;
}

.timer-area-container-inital{

  height  : 160px;
  padding-top: 20px;
  text-align: center;
}

.timer-area-inital {
  position: absolute;
  top     : 10px;
  border-radius: 33px;
  border       : none;
  width        : 210px;
  height       : 56px;
  text-align   : center;
  letter-spacing: -0.52px;
  font-size     : 26px;
  padding-top: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.video-area {
  position: relative;
  height  : 140px;
  text-align: center;
  margin-top: 5vh;
}

.video-area video {
  border-radius: 15px;
}

.content-space {
  margin-top: 80px;
}


/* App.css */

.button-container {

  width: 100%;
}

.button {
  width: 100%;
}

.textarea-container {
  width: 100%;
}

.textarea-container textarea {
  width: 100%;
}
.interview-column {
  padding-left: 7px !important;
  padding-right: 7px !important;
}