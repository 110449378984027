

  .footer {
    position:fixed;
    left:0;
    bottom:0;
    right:0;
  
    padding: 0;
    background: #808FFF 0% 0% no-repeat padding-box;

    font: normal normal 400 20px/22px Haffer SQ TRIAL;
    
    border-radius: 50px 50px 0 0;
    height: 70px;
  
    color:#000000;
    
    padding-left: 80px;
    padding-right: 80px;
  
  }

  .footer-logo-container{
    margin: 10px 0px 0px 0px;
    float: right;
  }

  .footer-text-container{
    margin: 30px 20px 0px 0px;
  }

  .footer-text{

 
    letter-spacing: 0px;

    margin-right: 20px;

  }