  nav {
    background-color: white;

  }


  .header {
    height: 120px;
    background-color: #FFFFFF;
    padding-top: 0;
  }

.header{
  padding-left: 80px;
  padding-right: 80px;
}